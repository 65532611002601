import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkButton } from 'sana/elements';

const Button = ({ model, id }) => {
  const elementId = `button-content-block-${id}`;
  let setLink = null;
  if (!model) 
    return null;

  const { buttonText, buttonAltText } = model;

  if (model.link) {
    setLink = {
      ...model.link,
      internallinkdata: model.link.internalLinkData,
      internallinktype: model.link.internalLinkType,
      externalurl: model.link.externalUrl,
    };
    delete setLink.externalUrl;
    delete setLink.internalLinkData;
    delete setLink.internalLinkType;
  }

  const wcagButtonAttributes = {};

  if (buttonAltText)
    wcagButtonAttributes['aria-label'] = buttonAltText;

  return (
    <div id={elementId} className={classNames('button-content-block')}>
      <LinkButton {...setLink} className="button-button-click" {...wcagButtonAttributes}>{buttonText}</LinkButton>
    </div>
  );
};

Button.propTypes = {
  model: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonAltText: PropTypes.string,
    link: PropTypes.object,
  }),
};

export default React.memo(Button);
